<template>
    <header class="text-white">
        <div class="animate__animated animate__fadeInTopRight barra">
            <div class="contact-item">
                <a href="https://wa.me/4432725058" target="_blank"
                    class="d-flex text-white align-items-center text-decoration-none">
                    <i class="bi bi-whatsapp"></i>
                    <span class="ms-2">+52 4432725058</span>
                </a>
            </div>
            <div class="contact-item">
                <a href="mailto:contacto@sip.coop" class="d-flex text-white align-items-center text-decoration-none">
                    <i class="bi bi-envelope"></i>
                    <span class="ms-2">contacto@sip.coop</span>
                </a>
            </div>

            <div class="contact-item">
                <a href="https://www.facebook.com/soluciones.integrales.de.personal" class="d-flex text-white align-items-center text-decoration-none">
                    <i class="bi bi-facebook"></i>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'HeaderComponent'
};
</script>

<style scoped>
.barra {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #002169;
    padding: 0.5rem 1rem;
    gap: 1rem;
}

.contact-item {
    display: flex;
    align-items: center;
    padding: 1;
    transition: all 0.3s ease;
}

.contact-item i {
    margin-right: 0.5rem;
    font-size: 1.1rem;
}

header {
    list-style: none;
    width: 100%;
    background-color: #002169;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

/* Large devices (desktops) */
@media (min-width: 1024px) {
    .contact-item {
        margin: 0 2.5rem;
    }
}

/* Medium devices (tablets) */
@media (max-width: 1023px) and (min-width: 769px) {
    .contact-item {
        margin: 0 1.5rem;
    }
}

/* Small devices (mobile phones) */
@media (max-width: 768px) {
    .barra {
        flex-direction: row;
        /* Changed to row for a more compact layout */
        padding: 0.3rem;
        gap: 0.5rem;
        /* Reduced gap */
        justify-content: space-around;
        /* Better space distribution */
    }

    .contact-item {
        margin: 0;
        padding: 0.2rem;
        /* Reduced padding */
        font-size: 0.8rem;
        /* Smaller font size */
    }

    .contact-item i {
        margin-right: 0.3rem;
        /* Reduced margin */
        font-size: 0.9rem;
        /* Smaller icons */
    }
}

/* Extra small devices */
@media (max-width: 480px) {
    .barra {
        padding: 0.2rem;
    }

    .contact-item {
        padding: 0.15rem;
    }

    .contact-item span {
        font-size: 0.75rem;
        /* Even smaller text for very small devices */
    }

    .contact-item i {
        font-size: 0.85rem;
        margin-right: 0.2rem;
    }
}
</style>